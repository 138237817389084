<template>
    <div>
        <b-loading v-if="preloading" v-model="preloading"/>

        <div v-else>
            <b-loading :is-full-page="true" v-model="isLoading"/>


            <div class="is-flex is-flex-direction-column is-align-items-center mt-4 mb-5">

                <div class="mb-5" v-if="(totalAmount < userMinWithdrawal) && user.withdrawal_limit === 1">
                    <b-notification
                        icon-size="is-small"
                        type="is-danger is-light"
                        :closable="false"
                        has-icon
                        class="message-body m-auto"
                    >
                        {{
                            $t('partenaire-gains.minWithdrawalWarning', {
                                minWithdrawal: this.userMinWithdrawal
                            })
                        }}
                    </b-notification>
                </div>

                <div class="card border-xl ">
                    <div class="card-body bg-warning p-3 border-xl">
                        <article class="">
                            <div class="text-center mb-4">
                                <p class="has-text-weight-bold mb-1">Mes gains</p>
                                <p class="title text-white">{{ benefits | toCurrency }} </p>
                            </div>

                            <div class="d-flex flex-column is-justify-content-end pt-4 flex-lg-row flex-xl-row">

                                <b-button
                                    :label="$t('partenaire-gains.transform')"
                                    @click.prevent="$refs.gainsModal.show()"
                                    :disabled="failedOrder.length >= 1 || totalAmount < 1"
                                    type="is-warning"
                                    rounded
                                    inverted
                                    outlined
                                    class="mr-lg-4 mb-2 mb-lg-0"
                                />

                                <b-button
                                    :label="$t('partenaire-gains.withdrawfunds')"
                                    @click.prevent="$router.push('/partner/withdraw')"
                                    :disabled="canNotWithDrawIncome"
                                    type="is-warning"
                                    rounded
                                    inverted
                                >
                                </b-button>
                            </div>
                        </article>
                    </div>
                </div>

                <p class="mt-4 text-muted">
                    <small>
                        {{
                            $t('partenaire-gains.limit', {maxCeilingWithdrawal: this.maxCeilingWithdrawal})
                        }}
                    </small>
                </p>

                <div class="d-flex">

                    <div v-if="failedOrder > 0" class="px-4">
                        <div class="alert alert-danger" role="alert">
                            <p class="mb-0">
                                {{ $t('partenaire-gains.errororder') }}
                                <br/>
                                {{ $t('partenaire-gains.correct') }}
                                <router-link to="/partner/orders#erreurs">
                                    {{ $t('partenaire-gains.yourerrors') }}
                                </router-link>
                            </p>
                        </div>
                    </div>

                    <div v-if="user.partner_status !== 'validate'" class="px-4">
                        <div class="alert alert-danger" role="alert">
                            <p class="mb-0">
                                {{ $t('partenaire-gains.attestation') }}
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            <section class="box">
                <nl-table ref="tableLogs" async default-sort="created_at" :columns.sync="columns" :get-data="getData">
                    <template #log="{ props }">
                        {{ props.row.log }}
                    </template>

                    <template #montant="{ props }">
                        {{ props.row.montant }}
                        <span v-if="props.row.entity_type === 12" class="tag is-success ml-2">
                            <a class="downloadPDF" @click="download(props.row)">
                                <i class="fas fa-file-download text-white"/>
                            </a>
                        </span>
                    </template>


                </nl-table>
            </section>
        </div>

        <TransfertGainsModal v-if="totalAmount >= 1" ref="gainsModal" @close="reloadCredits()"></TransfertGainsModal>

        <TransfertCreditsModal
            ref="creditsModal"
            :max-withdrawal="maxWithdrawal"
            :min-withdrawal="userMinWithdrawal"
            @close="reloadCredits()"
            v-if="totalAmount >= 200 || user?.withdrawal_limit === 0"
        >
        </TransfertCreditsModal>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import TransfertGainsModal from '@/components/PartnerSites/Modal/TransfertGainsModal'
import TransfertCreditsModal from '@/components/PartnerSites/Modal/TransfertCreditsModal'
import NlTable from '@/components/UI/Table/NlTable.vue'
import Download from "@/utils/download";

export default {
    name: 'PartnerGains',
    title: 'partenaire-gains.title',
    components: {
        NlTable,
        TransfertGainsModal,
        TransfertCreditsModal
    },
    data() {
        return {
            searchable: true,
            preloading: true,
            isLoading: true,
            loading: false,
            minWithDrawalIfLimited: 200,
            minWithdrawalConfig: 0,
            maxCeilingWithdrawal: 0,
            filter: {
                filters: null,
                sortField: null,
                sortOrder: null,
                page: null
            },
            columns: [
                {
                    label: this.$t('global.time.date'),
                    field: 'created_at',
                    type: 'date',
                    inputType: 'date',
                    centered: false,
                    fieldOption: {
                        dateFormat: 'medium'
                    }
                },
                {
                    label: this.$t('partenaire-gains.event'),
                    field: 'log',
                    centered: false,
                    custom: true
                },
                {
                    label: this.$t('credits.amount'),
                    field: 'montant',
                    centered: false,
                    custom: true
                }
            ]
        }
    },
    created() {
        this.getData()
    },
    computed: {
        ...mapState('auth', {
            benefits: state => Number.parseFloat(state.user?.gains)
        }),
        ...mapState('partner_sites', {
            withdrawGains: state => state.withdrawGains,
            totalAmount: state => state.withdrawGains.amount,
            user: state => state.user,
            failedOrder: state => state.failed_order,
            bonus: state => state.bonus
        }),
        maxWithdrawal() {
            if (this.benefits < this.maxCeilingWithdrawal) return this.benefits
            else return this.maxCeilingWithdrawal
        },
        userMinWithdrawal() {
            if (this.user.withdrawal_limit === 1) return this.minWithDrawalIfLimited
            else return 0
        },
        canNotWithDrawIncome() {
            if (!this.user || this.user.partner_status === 'invalidate')
                return true

            if (this.totalAmount < this.userMinWithdrawal && this.user.withdrawal_limit === 1) return true

            if (this.failedOrder > 0) return true

            return false
        }
    },
    methods: {
        ...mapActions('auth', {
            getIncomes: 'getIncomes'
        }),
        ...mapActions('billing', ['downloadUserInvoice']),
        ...mapMutations('auth', {setBenefits: 'setBenefits'}),
        ...mapMutations(['toggleGTCModal']),
        reloadCredits() {
            this.$refs.tableLogs.loadAsyncData()
        },
        getData(params) {
            this.isLoading = true

            return new Promise(
                function (resolve, reject) {
                    this.getIncomes(params)
                        .then(data => {

                            this.minWithdrawalConfig = data.min_withdrawal
                            this.maxCeilingWithdrawal = data.max_withdraw

                            this.$store.commit('partner_sites/setBonus', data.bonus)
                            this.$store.commit('partner_sites/setUser', data.user)
                            this.$store.commit('partner_sites/setGains', data.gain)
                            this.$store.commit('partner_sites/setFailedOrder', data.failed_order)
                            this.$store.commit('partner_sites/setWithdrawGains', {
                                saveDefaultPayment: false,
                                invoiceChoice: 'generation',
                                amount: Math.round(data.total_amount * 100) / 100,
                                addressPaypal: data.user.email_paypal,
                                iban: data.user.iban,
                                bic: data.user.bic,
                                bankName: data.user.banque,
                                firstname: data.user.firstname,
                                lastname: data.user.lastname,
                                company: data.user.company,
                                siret: data.user.siret,
                                address: data.user.address,
                                postalCode: data.user.cp,
                                city: data.user.ville,
                                tvaNumber: data.user.tva_number
                            })

                            this.preloading = false
                            this.isLoading = false
                            resolve(data.gain)
                        })
                        .catch(error => {
                            console.log(error)
                            reject(error)
                        })
                }.bind(this)
            )
        },
        computeTransferAmount() {
            this.transferAmount = parseInt(this.transferAmount * 100) / 100
        },
        download(log) {
            this.downloadUserInvoice(log.id).then((response) => {
                Download.download(
                    response,
                    `Facture_${this.moment(log.created_at).format('YYYY-MM-DD')}`
                )
            })
        }
    }
}
</script>

<style scoped>
/deep/ .b-numberinput input {
    text-align: left !important;
}

.border-xl {
    border-radius: 30px;
}
</style>